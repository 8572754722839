  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px !important;
    }
  }

  @media (min-width: 1600px) {
  .aboutvideo-box video { height: auto;}
  }
  
  @media (min-width: 1920px) and (max-width: 2560px) {
    .home-quote { padding: 240px 0px 130px;}
    .home-quote h4 { font-size: 34px;}
    .history-slider { margin-right: -500px;}
  }
  
  
  @media (min-width: 1801px) and (max-width: 1919px) {
    .home-quote { padding: 220px 0px 120px;}
    .home-quote h4 { font-size: 32px;}
    .history-slider { margin-right: -420px;}
  }
  

  @media (min-width: 1681px) and (max-width: 1800px) {
    .home-quote { padding: 210px 0px 110px;}
    .history-slider { margin-right: -420px;}
  }
  

  @media (min-width: 1600px) and (max-width: 1680px) {
    .home-quote { padding: 200px 0px 110px;}
    .history-slider { margin-right: -320px;}
  }
  
  
  @media (min-width: 1441px) and (max-width: 1599px) {
    .home-quote { padding: 200px 0px 110px;}
    .history-slider { margin-right: -280px;}
  }
  

  @media (min-width: 1400px) and (max-width: 1440px) {
    .home-quote { padding: 180px 0px 100px;}
  }
  

  @media (min-width: 1300px) and (max-width: 1366px) {

  }
  

  @media (min-width: 992px) and (max-width: 1200px) {
   
    .play-butn { bottom: 90px;}
    .build-slider { margin-left: 40px;}
    .buildslide-box div { margin: 30px 0px 0px 0px;}
    .quality-box { min-height: 314px;}
    .service-box div { width: 92%;}
    .service-box div h4 {font-size: 18px;}
    .gettouch-form {padding: 70px 20px 30px; margin-left: 0px;}
    .gettouch-form .form-feild { padding-left: 6px; padding-right: 6px;}
    .testimain-box { padding: 40px;}
    .testimain-box .heading { font-size: 35px;}
    .testimain-box .heading img { width: 22px;}
    .process-row { width: 92%;}
    .process-box { width: 23%; padding: 15px;}
    .process-box span { letter-spacing: .7px;}
    .story-slidebox div { padding: 18px;}
    .story-slidebox div span { font-size: 16px;}
    .execute-textbox .heading {font-size: 50px;}
    .expertise-video .img-responsive, .expertise-video video, .expertise-video iframe { height: 400px;}

    .subheader-text { bottom: 40px;}

    .medialist-box div { padding: 15px;}
    .medialist-box div span { font-size: 16px;}

    .projectlist-box div { padding: 20px;}
    .projectlist-box div h4, .projectbig-box h4 { font-size: 48px;}
    .projectbig-box { padding: 40px 20px 70px;}

    .contact-form { padding-left: 12px;}
    .contact-career { padding: 60px 30px 30px;}
    .contactjob-list span { width: 62%;}
    .contactpage-form::before{width: 960px;}

    .careerpage-head { padding-right: 20px;}
    .servicepage-tag { margin-left: 40px;}

    .blogdetail-box{margin-top: -22px;}

    .prodetail-image { padding-right: 10px;}
    .prodetail-content .heading { margin-top: 10px;}
    .prodetail-content ul { margin: 20px 0px !important;}
    .prodetail-content ul li { font-size: 14px;  margin-right: 15px; padding-left: 12px;}
    .half-view img { height: 401px;}

    .aboutdesign-slider { padding: 30px;}
    .designslide-box .heading { width: auto; }
    .history-slider { margin-right: -300px;}
    .leadership-box div { padding: 20px;}
    .aboutquote-box p {width: 60%;}
    .aboutpage-quality .quality-box { min-height: 333px; padding: 15px;}
    .aboutpage-quality .quality-box span { font-size: 15px; letter-spacing: normal;}
    .history-box { min-height: 225px;}
    .aboutvideo-box .img-responsive { height: 500px}

    .job-discrip .heading{ font-size: 58px;}

    .errorpage-text div { bottom: 90px;}

    .thankyou-text { height: auto;}
    .thankyou-text div { position: unset; transform: none;}

    .keypoint-box { padding: 20px; min-height: 251px;}
    .keypoint-box span { letter-spacing: .7px;}

    .servicelist-box div { padding: 0px 25px 40px;}
    .servicelist-box div h4 { font-size: 50px;}
    .servicelist-box .img-responsive { height: 430px;}

    .policyaccord-head { font-size: 19px;}
    .buildslide-image{min-height: 370px;}

    .sub-header.project-sub{min-height: 299px;}
    .projectdetail-big{min-height: 461px;}
    .small-projectlist.projectlist-box{min-height: 461px;}

    .medialist-box small{min-height:145px;}

  }
  
  
  @media (min-width: 1200px) and (max-width: 1200px) {

    .play-butn { bottom: 50px;}
    .build-slider { margin-left: 70px;}
    .buildslide-box div { margin: 40px 0px 0px 0px;}
    .quality-box { min-height: 248px;}
    .service-box div { width: 88%;}
    .service-box div h4 {font-size: 20px;}
    .gettouch-form {padding: 80px 20px 50px;}
    .gettouch-form .form-feild { padding-left: 12px; padding-right: 12px; }
    .testimain-box { padding: 50px;}
    .testimain-box .heading { font-size: 42px;}
    .testimain-box .heading img { width: 26px;}
    .process-row { width: 90%;}
    .process-box { width: 22%; padding: 25px;}
    .process-box span { letter-spacing: 1.2px;}
    .story-slidebox div { padding: 30px;}
    .story-slidebox div span { font-size: 18px;}
    .execute-textbox .heading {font-size: 52px;}
    .expertise-video .img-responsive, .expertise-video video, .expertise-video iframe { height: 480px;}

    .subheader-text { bottom: 60px;}

    .medialist-box div { padding: 20px;}
    .medialist-box div span { font-size: 17px;}

    .projectlist-box div { padding: 40px;}
    .projectlist-box div h4, .projectbig-box h4 { font-size: 54px;}
    .projectbig-box { padding: 40px 40px 70px;}

    .contact-form { padding-left: 30px;}
    .contact-career { padding: 60px 40px 40px;}
    .contactjob-list span { width: 68%;}
    .contactpage-form::before{width: 1140px;}

    .careerpage-head { padding-right: 50px;}

    .servicepage-tag { margin-left: 70px;}

    .prodetail-image { padding-right: 40px;}
    .prodetail-content .heading { margin-top: 30px;}
    .prodetail-content ul { margin: 30px 0px !important;}
    .prodetail-content ul li { font-size: 15px;  margin-right: 25px; padding-left: 15px;}
    .half-view img { height: 487px;}

    .aboutdesign-slider { padding: 40px 50px;}
    .designslide-box .heading { width: 53%; font-size: 70px;}
    .history-slider { margin-right: -220px;}
    .leadership-box div { padding: 30px;}
    .aboutquote-box p {width: 48%;}
    .aboutpage-quality .quality-box { min-height: 268px;}
    .history-box { min-height: 204px;}
    .aboutvideo-box .img-responsive { height: 500px}

    .job-discrip .heading{ font-size: 70px;}

    .errorpage-text div { bottom: 70px;}

    .keypoint-box { padding: 25px; min-height: 240px;}
    .keypoint-box span {letter-spacing: 1.2px;}

    .servicelist-box div { padding: 0px 30px 40px;}
    .servicelist-box div h4 { font-size: 54px;}
    .servicelist-box .img-responsive { height: 515px;}

    .policyaccord-head { font-size: 21px;}

    .medialist-box small{min-height:175px;}
  
  }
  

  @media (min-width: 768px) and (max-width: 991px) {
   
  .header-menulink li { margin-right: 12px;}
  .hide-mobile{ padding-right: 0px !important;}
  .rightside-menu{ padding-left: 0px !important;}

  .homebanner-detail{height: 95vh;}
  .homebanner-detail .img-responsive, .homebanner-detail video { height: 95vh;} 
  .build-slider { margin-left: 0px;}
  .buildslide-box .heading { font-size: 55px;}
  .buildslide-box div { margin: 20px 0px 0px 0px;}
  .buildslide-box p { margin-bottom: 15px;}
  .build-arrows { margin-top: 20px; width: auto;}
  .build-slider .slick-dots { bottom: -49px;}

  .homecount-box h4, .homecount-box h3 {font-size: 40px;}
  .homecount-box p { font-size: 18px;}
  
  .whyslide-detail { width: 50%; margin: 40px;}
  .why-slider .slick-slide div .img-responsive{ height: 440px; object-fit: cover;}
  .why-slider .slick-dots { bottom: 40px; padding-left: 40px !important;}

  .home-facility p { margin-top: 30px;}

  .service-slider { padding-bottom: 40px;}

  .expertise-video { margin-right: 0px;}
  .play-expert, .play-expert:before { width: 70px; height: 70px;}
  .expertise-detail p { margin: 20px 0px 0px;}

  .storyarea-point { margin-top: 20px;}

  .quality-row { padding: 40px 20px;}
  .quality-box { padding: 15px; min-height: 336px;}

  .execute-container .row{ align-items: center;}
  .execute-textbox .heading { font-size: 42px;}
  .execute-process p { font-size: 12px; letter-spacing: 0;}
  .execute-textbox {margin-right: 0px;}
  .expertise-video .img-responsive, .expertise-video video, .expertise-video iframe { height: 320px;}

  .story-slidebox div { padding: 20px;}

  .process-row { width: 100%;}
  .process-box { padding: 15px; width: 23.5%;}
  .process-box span { font-size: 13px; letter-spacing: 0;}

  .testi-image img { border-radius: 10px 10px 50px 0px;}
  .testimain-box { border-radius: 0px 50px 10px 10px;}
  .testi-image { padding-right: 12px !important;}
  .no-leftpadd { padding-left: 12px !important;}

  .service-box div { width: 90%;}

  .gettouch-form { padding: 60px 20px 30px;}

  .subscribe-form { width: 293px;}
  .footer-logo .img-responsive { width: 230px;}
  .social-copywrite p { width: 380px;}
  .footlink-1{ width: 51%;}

  .faq-leftside{ padding-right: 12px !important;}
  .faq-rightside{ padding-left: 12px !important;}

  .sub-header .img-responsive{ width: 100%; height: 350px; object-fit: cover;}
  .sub-header .about-banner-wrap, .sub-header .about-banner-wrap img{min-height: 350px;}

  .small-projectlist .img-responsive { height: 343px;}
  .projectlist-box div { padding: 20px;}
  .projectlist-box div h4, .projectbig-box h4 { font-size: 38px;}
  .projectbig-box { padding: 20px 20px 50px;}
  .projectdetail-slider .slick-dots { bottom: 20px;}
  .projectlist-box div span, .projectbig-box span { font-size: 12px;}
  .projectlist-box div span:after{ width: 12%; flex: none;}
  .small-projectlist.projectlist-box div span{justify-content: space-between; flex-wrap: wrap;}
  .small-projectlist.projectlist-box div span small{width: 48%;margin-top: 3px;}
  .small-projectlist.projectlist-box div span small:nth-child(even){text-align: right;}
  .small-projectlist.projectlist-box div span small strong{ display: none;}
  .small-projectlist.projectlist-box div span:after{ display: none;}

  .contactpage-form::before { width: 720px;}
  .contact-form { padding-left: 5px;}
  .contact-form .heading { font-size: 52px;}
  .contact-address { flex-direction: column;}
  .contact-address div { width: 80%;}
  .contact-career { padding: 50px 20px 20px;}
  .contactjob-list a { margin-left: 15px;}
  .contactjob-list span {width: 85%;}

  .careerpage-head { padding-right: 0px; margin-bottom: 40px; text-align: center;}

  .servicepage-tag { margin-left: 0px;}
  .servicepage-box.service-box div { width: 93%;}
  .servicepage-box.service-box div h4 { font-size: 16px;}
  .service-box div { padding: 10px 0px 10px;}
  .other-service { background: linear-gradient(to bottom, #F3F1F0, rgb(255 255 255 / 0%) 70%);}
  .service-keypoint { margin: 60px 0px 36px;}
  .keypoint-box { margin-bottom: 24px; min-height: 198px;}

  .errorpage-text .heading{font-size: 130px;}
  .errorpage-text div { bottom: auto; transform: translateY(-50%); top: 50%;}

  .prodetail-image {padding-right: 0px; margin-bottom: 40px;}
  .prodetail-content span::after { width: 94%;}
  .half-view img { height: 298px;}

  .abouthead-image { padding-right: 0px;}
  .abouthead-detail .heading { margin: 20px 0px 20px; font-size: 52px;}
  .aboutvideo-box video { height: 480px;}
  .vision-detail { padding-right: 0px;}
  .vision-detail .heading { font-size: 65px;}
  .vision-detail p { margin: 20px 0px 30px; padding-left: 0px;}
  .about-design .no-leftpadd {  padding-left: 0px !important;}
  .aboutdesign-slider { padding: 25px;}
  .designslide-box .heading { width: auto; font-size: 55px;}
  .leader-area { padding: 60px 0px 30px;}
  .leadership-box { margin-bottom: 30px;}
  .leadership-box .img-responsive { width: 40%;}
  .leadership-box div { width: 60%;}
  .aboutquote-box p { width: 80%;}
  .aboutpage-quality .quality-box { min-height: 228px; margin-top: 24px;}
  .aboutpage-quality .homequality-haed img { margin: 30px 0px 16px;}
  .fliplogo-card { height: 60px; width: 23%;}
  .flip-container img { width: 70%;}
  .jobhead-image { padding-right: 0px;}
  .job-discrip .heading{ font-size: 46px;}
  .aboutvideo-box .img-responsive { height: 480px}

  .servicelist-box .img-responsive { height: 343px;}
  .servicelist-box div { padding: 0px 20px 25px;}
  .servicelist-box div h4 { font-size: 38px;}
 
  .termpolicy-text {padding-right: 0px; margin-bottom: 40px;}
  .buildslide-image{min-height: 270px;}

  .sub-header.project-sub{min-height: 350px;}
  .projectdetail-big{min-height: 343px;}
  .small-projectlist.projectlist-box{min-height: 343px;}

  .medialist-box small{min-height:226px;}

  }
  

  @media (max-width: 767px) {
    .hide-mobile, .rightside-menu{display: none;}
    .mobile-menu{ display: flex;}

    .desktop-team{display: none;}
    .mobile-team{ display: block;}
  }


  @media (min-width: 600px) and (max-width: 767px) {
   
    .heading { font-size: 55px;}

    .homebanner-detail{height: 92vh;}
    .homebanner-detail .img-responsive, .homebanner-detail video { height: 92vh;}
    .play-butn { margin: 30px auto 0px; position: relative; bottom: 0px;}

    .home-build { margin: 50px 0px 60px;}
    .build-slider { margin: 40px 0px 0px;}
    .buildslide-box div { margin: 30px 0px 0px 0px;}
    .build-arrows { margin-top: 40px;}
    .build-slider .slick-dots { bottom: -69px;}

    .home-counter .row { padding: 25px 0px 30px;}
    .homecount-box { margin: 20px 0px;}
    .homecount-box h4, .homecount-box h3 { font-size: 40px;}
    .homecount-box p { font-size: 20px;}

    .why-slider .slick-slide div .img-responsive{ height: 450px; object-fit: cover;}
    .whyslide-detail { width: 80%; margin: 20px; display: flex; justify-content: space-between; flex-direction: column; height: 80%;}
    .why-slider .slick-dots { bottom: 20px; padding-left: 20px !important;}

    .home-facility { margin-top: 50px;}
    .home-facility p { margin-top: 30px;}
    .facility-slider { margin: 30px 0px 50px;}

    .home-expert { margin: 50px 0px;}
    .expertise-video { margin-right: 0px;}
    .expertise-detail{margin-top: 30px;}
    .expertise-detail p { margin: 30px 0px 0px;}
    .expertise-video .img-responsive, .expertise-video video, .expertise-video iframe { height: 380px;}

    .execute-textbox { margin-right: 0px; margin-top: 30px; height: auto;}
    .execute-process { margin-top: 15px;}
    .execute-process div { margin-top: 20px; width: 100%;}

    .mamu-videoarea { margin: 50px 0px 0px;}
    .manu-imageslider { margin: 30px 0px 50px !important; height: auto;}

    .homequality-haed .heading { font-size: 50px;}
    .quality-row { padding: 40px 5px 40px;}
    .quality-box { min-height: auto; margin-bottom: 20px;}
    .quality-box p { line-height: 1.7;}
    .homepage-testi .quality-row { padding: 40px 30px 40px;}
    .aboutpage-quality .quality-box { min-height: auto;}

    .storyarea-point { margin-top: 20px;}

    .home-project { margin: 50px 0px;}
    .project-box { margin-bottom: 40px; border-radius: 10px;}
    .project-box a { padding: 20px 15px 0px; }
    .project-box .heading { font-size: 38px; margin-bottom: 15px;}
    .project-box p { font-size: 13px;}
    .projectbox-slider { margin-top: 22px;}

    .home-testimonial { background: #E0DBD9; padding: 50px 0px;}
    .testi-image { padding-right: 12px !important;}
    .no-leftpadd { padding-left: 12px !important;}
    .testi-image img { border-radius: 10px 10px 40px 0px;}
    .testimain-box { border-radius: 0px 40px 10px 10px; padding: 22px;}
    .testimain-box .heading { font-size: 48px;}

    .home-quote { padding: 140px 0px; background-position: center;}
    .home-quote label { margin: 20px 0px 22px;}
    .home-quote h4 { font-size: 26px; margin: 0px auto; width: 82%;}

    .home-process { padding: 50px 0px; margin-bottom: 0px;}
    .home-process .col-lg-12{ position: relative;}
    .vector-heading .heading { margin: 30px 0px 40px;}
    .process-row { flex-direction: column; width: 100%; align-items: end; padding-left: 52px;}
    .process-box { padding: 20px; width: 100%; margin: 15px 0px;}
    .process-top .process-box:before, 
    .process-bottom .process-box:before { bottom: auto; height: 1px; width: 36px; left: -36px; right: auto; top: 30px;}
    .process-top .process-box:after, 
    .process-bottom .process-box:after { bottom: auto; top: 22px; right: auto; left: -36px; border-width: 8px 0 8px 8px; border-color: transparent transparent transparent #B8ABA5;}
    .process-top{padding-top: 22px;}
    .process-bottom{padding-bottom: 22px;}
    .process-divider { position: absolute; margin: 0px; flex-direction: column; height: 100%; left: 15px; top: 0; bottom: 0;}
    .process-divider img { width: 26px; background: none;}
    .process-divider::before { height: 100%; width: 1px; left: 50%; top: 0; bottom: 0; transform: translateX(-50%); z-index: 0;}

    .portfolio-slidebox { padding: 0px 5px;}
    .portfo-arrows { margin-top: 30px;}

    .ceo-message { margin: 50px 0px;}
    .ceomessage-box { padding: 0px 50px;}
    .ceomessage-box h4 { font-size: 26px;}
    .message-shape-2 { right: -23px; width: 90px;}
    .message-shape-1 { left: -23px; width: 90px;}

    .story-slidebox {padding: 0px 5px;}
    .story-slidebox div {padding: 20px;}

    .home-service { margin: 50px 0px;}
    .service-slider{ padding-bottom: 40px;}
    .service-slider .slick-slide{ padding: 0px 5px;}

    .home-media { margin: 50px 0px;}
    .homemedia-box { padding: 30px 50px 40px;}
    .media-shape-1 { left: -23px; width: 90px;}
    .media-shape-2 { right: -23px; width: 90px;}
    .homemedia-box .shape-divider { margin: 20px 0px;}
    .medialogo-slider { margin: 0px;}
    .medialogo-slider .slick-slide div { padding: 0px 10px;}

    .get-touch { padding: 50px 0px;}
    .gettouch-text .heading { text-align: center; margin-bottom: 50px;}
    .gettouch-text p { display: none;}
    .gettouch-form { padding: 70px 40px 40px; margin-left: 0px;}

    .footer { padding: 30px 0px 12px; margin-bottom: 56px;}
    .footlink-1{ text-align: center; width: 100%;}
    .footlink-1 li { margin-right: 0px; margin-bottom: 3px;display: block;}
    .footlink-1 li a { letter-spacing: 1.5px;}
    .footlink-2{display: none;}
    .footer-logo { margin: 30px 0px 50px; flex-direction: column-reverse;}
    .subscribe-form p { font-size: 17px; margin-bottom: 12px; text-align: center;}
    .footer-logo .img-responsive { width: 210px; margin-top: 40px;}
    .social-copywrite p { color: rgb(255 255 255 / 35%);}
    .social-copywrite { justify-content: center; flex-direction: column;}
    .desktop-footersocial{display: none;}
    .mobile-footersocial{ display: block;}
    .footer-social li { margin: 0px 15px;}
    .footer-social li a { font-size: 13px;}
    .footer-term{ display: block;}
    .subscribe-form { width: 80%;}

    .faq-page { background: #fff; padding-bottom: 40px; margin-top: 10px;}
    .faq-accord .accordion-button { font-size: 15px;}
    .faq-leftside{ padding-right: 12px !important;}
    .faq-rightside{ padding-left: 12px !important;}
    .faq-more{ display: block;}

    .page-filter ul li a { font-size: 13px; padding: 8px 10px;}
    .page-filter ul li { margin-right: 5px;}

    .sub-header .img-responsive{ width: 100%; height: 330px; object-fit: cover;}
    .sub-header .about-banner-wrap, .sub-header .about-banner-wrap img{min-height: 330px;}
    .subheader-text { bottom: 40px;}

    .media-page { margin: 30px 0px 40px;}
    .mediapost-more { margin-top: 10px !important;}
    .medialist-box div span { min-height: auto;}

    .project-listing { margin-bottom: 40px;}
    .projectlist-box div { padding: 20px;}
    .projectlist-more{ display: block; margin-top: 30px !important;}
    .projectlist-box .img-responsive{ object-fit: cover; height: 400px;}
    .projectlist-box div span:before{display: none;}
    .projectlist-box div h4, .projectbig-box h4 { font-size: 40px;}
    .projectlist-curve, .projectbig-curve { width: 90px;}
    .projectbig-box{ padding: 20px 20px 58px;}
    .projectdetail-slider .slick-slide div .img-responsive{ object-fit: cover; height: 460px;}
    .projectdetail-slider .slick-dots { bottom: 25px;}
    .projectlist-box div span, .projectbig-box span { font-size: 12px;}

    .contactpage-form {padding: 40px 0px 30px;}
    .contactpage-form::before { width: 40%;}
    .contact-map{display: none;}
    .contact-form {padding-left: 0px; text-align: center;}
    .contact-address { flex-direction: column; align-items: center; margin-top: 30px;}
    .contact-address div { width: 50%; text-align: center;}
    .contactpage-career { margin-bottom: 50px;}
    .vendor-career { flex-direction: column;}
    .contact-career { width: 100%;}
    .contactjob-list { align-items: start; padding: 18px; flex-direction: column;}
    .contactjob-list span {font-size: 18px; margin-bottom: 8px; width: 100%;}

    .career-page { margin: 50px 0px;}
    .careerpage-head {padding-right: 0px; text-align: center; margin-bottom: 40px;}
    .careerjob-list span { font-size: 18px; margin-bottom: 8px; width: 100%;}
    .careerjob-list { align-items: start; flex-direction: column;}

    .servicepage-detail { padding: 50px 0px; background: linear-gradient(to top, #F3F1F0, rgb(255 255 255 / 0%) 55%);}
    .servicepage-tag { margin-left: 0px; margin-bottom: 0px;}
    .servicepage-detail .build-slider { margin: 15px 0px 0px;}
    .servicepage-detail .buildslide-box div { margin: 20px 0px 0px 0px;}
    .servicepage-detail .homebuild-image { margin-bottom: 40px;}
    .servicepage-detail .build-arrows { margin-top: 20px;}
    .servicepage-detail .homebuild-shape, 
    .servicepage-detail .homebuild-shape-2 {filter: brightness(0) invert(1); }
    .serviceslide-image { margin-bottom: 50px;}
    .other-service { background: linear-gradient(to bottom, #F3F1F0, rgb(255 255 255 / 0%) 60%); padding: 50px 0px;}
    .other-service .service-box { margin-bottom: 20px;}
    .servicedetail-curve { filter: brightness(0) invert(1);}
    .servicepage-head { padding: 40px 0px;}
    .servicepage-slider { margin: 0px;}
    .service-keypoint { margin: 50px 0px 35px;}
    .keypoint-box { margin-bottom: 15px; min-height: auto;}
    .other-service .portfolio-slider { padding-bottom: 30px;}
    .otherproj-arrows{ display: flex;}
    .service-process .quality-row { padding: 40px 5px 0px;}

    .bloglist-card-box{margin-top: 30px;}
    .bloglist-cards{margin-bottom: 50px;}

    .blogdetail-content{padding-bottom: 50px; padding-top: 50px;}
    .blogdetail-box{margin: 0;}
    .blogdetail-inner{padding: 70px 20px;}
    .blogdetail-inner h1{ font-size: 22px;}
    .bloginner-image{flex-direction: column;}
    .bloginner-image img{width: 100%; margin-bottom: 30px;}
    .bloginner-image img:last-child{margin-bottom: 0px;}
    .blogdetail-social{flex-direction: column;}
    .blogdetail-social span{margin-right: 0; margin-bottom: 20px; font-weight: 600;}
    .blogdetail-social ul li a{width: 30px; height: 30px;}
    .blogdetail-other{margin: 50px 0 60px;}
    .otherblog-arrows{display: flex; align-items: center; justify-content: center; margin-top: 50px;}
    .otherblog-arrows .default-arrowleft, 
    .otherblog-arrows .default-arrowright{position: static; margin: 0 9px; transform:rotate(45deg);}

    .errorpage-text div { bottom: auto; transform: translateY(-50%); top: 50%;}
    .errorpage-text .heading{font-size: 120px;}

    .aboutpage-head{margin: 50px 0;}
    .abouthead-detail .heading { margin: 20px 0px 25px;}
    .abouthead-image{display: none;}
    .aboutvideo-box video { height: 400px;}
    .aboutvideo-box .img-responsive{height: 420px;}
    .aboutvideo-box span{text-align: center;}
    .aboutvideo-box div{top: 60%; transform: translate(-50%, -60%);}
    .aboutvideo-box span br{display: block;}
    .vision-detail p{padding-left: 0; margin: 30px 0px 35px;}
    .aboutpage-vision{margin: 50px 0;}
    .vision-detail{padding-right: 0; margin-top: 20px;}
    .design-image .slick-slider { border-radius: 10px 10px 35px 35px;}
    .no-rightpadd{padding-right: 12px!important;}
    .aboutdesign-slider{border-radius: 40px 40px 10px 10px; padding: 40px;}
    .designslide-box .heading { width: auto;}
    .leader-area{padding: 50px 0px 25px;}
    .leadership-box { margin-bottom: 30px;}
    .aboutquote-box{padding: 40px 30px;}
    .aboutquote-box p{width: 75%;}
    .aboutquote-box span{width: 240px; margin: 0 auto;}
    .aboutquote-box .heading{font-size: 28px;}
    .about-service .home-service{margin: 50px 0px;}
    .home-service .default-btn{display: none;}
    .about-media { padding: 30px 0px 50px;}
    .about-partner { margin-bottom: 25px;}
    .about-footprint { margin-top: 50px;}
    .fliplogo-card { height: 50px; width: 23%;}
    .flip-container img { width: 80%;}
    .about-faq{padding-bottom: 50px;}
    .aboutpage-quality{margin-bottom: 50px;}
    .projectdetail-head { margin: 50px 0px;}
    .prodetail-image {padding-right: 0px; margin-bottom: 30px;}
    .prodetail-content .heading { margin-top: 20px; font-size: 48px;}
    .prodetail-content ul { margin: 20px 0px !important;}
    .prodetail-content ul li { margin: 6px 0px; width: 50%; vertical-align: middle;}
    .porfolio-view img { height: 380px !important;}
    .prodetail-other { margin: 50px 0px;}
    .project-otherslider { padding-bottom: 30px;}
    .project-otherslider .slick-slide > div { padding: 0px 5px;}
    .mobile-team .slick-slider .slick-slide > div { padding: 0px 10px;}
    .about-team { margin-bottom: 45px;}
    .teams-popup .modal-dialog { max-width: 580px;}
    .teampopup-box div { padding: 30px 30px;}
    .teams-popup .pop-close { right: 5px; top: 5px;}

    .about-history .center-heading { margin-bottom: 40px;}
    .aboutmedia-filter li { margin: 0px 8px;}

    .thankyou-page { padding: 20px 0px;}
    .thankyou-text .heading { font-size: 80px; margin-bottom: 20px;}

    .jobhead-image { top: 0px; padding-right: 0px; margin: 0px 0px 30px;}
    .career-detail { padding: 35px 0px 50px;}
    .job-typelocate { padding: 12px 0px; margin: 30px 0px 25px; justify-content: space-between;}
    .job-typelocate div { margin-right: 0px; width: 49%;}
    .job-discrip .default-btn{width: 100%;}

    .service-listpage { margin: 50px 0px 30px;}
    .servicelist-box .img-responsive { height: 420px;}
    .servicelist-box div { padding: 0px 20px 25px;}
    .servicelist-box div h4 { font-size: 40px;}

    .term-policy { padding: 40px 0px 50px;}
    .termpolicy-text {padding-right: 0px; margin-bottom: 40px;}
    .termpolicy-text .heading { margin-bottom: 15px;}

    .buildslide-image{min-height: 430px;}

    .sub-header.project-sub{min-height: 330px;}
    .projectdetail-big{min-height: 460px;}
    .small-projectlist.projectlist-box{min-height: 400px;}

    .medialist-box small{min-height:350px;}

  }
 

  @media (max-width: 599px) {
    .heading { font-size: 45px;}

    .default-arrowleft, .default-arrowright { width: 42px; height: 42px;}

    .header-gap { margin-top: 59px;}

    .header-area { padding: 15px 0px;}
    .logo img { width: 130px;}
    
    .homebanner-detail{height: 400px;}
    .homebanner-detail .img-responsive, .homebanner-detail video { height: 400px;}
    .homebanner-detail div { width: 100%; padding: 0px 15px;}
    .play-butn { margin: 30px auto 0px; position: relative; bottom: 0px;}

    .home-build { margin: 50px 0px 60px;}
    .homebuild-shape, .homebuild-shape-2 { width: 90px;}
    .build-slider { margin: 40px 0px 0px;}
    .buildslide-box div { margin: 30px 0px 0px 0px;}
    .build-arrows { margin-top: 40px;}
    .build-slider .slick-dots { bottom: -67px;}

    .home-counter .row { padding: 25px 0px 30px;}
    .homecount-box { margin: 20px 0px;}
    .homecount-box h4, .homecount-box h3 { font-size: 40px;}
    .homecount-box p { font-size: 20px;}

    .why-slider::after { background: rgba(0, 0, 0, 45%);}
    .why-slider .slick-slide div .img-responsive{ height: 450px; object-fit: cover;}
    .whyslide-detail { width: 80%; margin: 20px; display: flex; justify-content: space-between; flex-direction: column; height: 80%;}
    .whyslide-shape { right: -23px; width: 90px;} 
    .why-slider .slick-dots { bottom: 20px; padding-left: 20px !important;}

    .home-facility { margin-top: 50px;}
    .home-facility p { margin-top: 30px;}
    .facility-slider { margin: 30px 0px 50px;}
    .home-facility .heading { font-size: 45px;}

    .choose-box { text-align: left;}

    .home-expert { margin: 50px 0px;}
    .expertise-video { margin-right: 0px;}
    .expertise-detail{margin-top: 30px;}
    .expertise-detail p { margin: 30px 0px 0px;}
    .expertise-detail .heading { font-size: 45px;}
    .play-expert, .play-expert:before { width: 70px; height: 70px;}
    .expertise-video .img-responsive, .expertise-video video, .expertise-video iframe { height: 330px;}

    .home-execute{ background: #000000;}
    .execute-container { border-radius: 0; padding: 35px 15px;}
    .execute-textbox { margin-right: 0px; margin-top: 30px; height: auto;}
    .execute-textbox .heading { font-size: 45px;}
    .execute-process { margin-top: 15px;}
    .execute-process div { margin-top: 20px; width: 100%;}

    .mamu-videoarea { margin: 50px 0px 0px;}
    .manu-imageslider { border-radius: 0px; padding: 30px 0px; margin: 30px -12px 0px !important; height: auto;}

    .homequality-haed .heading { font-size: 45px;}
    .quality-row { padding: 40px 5px 40px;}
    .quality-box { min-height: auto; margin-bottom: 20px;}
    .quality-box p { line-height: 1.7;}
    .aboutpage-quality .quality-box { min-height: auto;}

    .home-project { margin: 50px 0px 50px;}
    .project-box { margin-bottom: 40px; border-radius: 10px;}
    .project-box a { padding: 20px 15px 0px; }
    .project-box .heading { font-size: 36px; margin-bottom: 15px;}
    .project-box p { font-size: 13px;}
    .projectbox-slider { margin-top: 22px;}
    .projectbox-slider .pro-curve { width: 90px;}
    .projectbox-slider .slick-slider .img-responsive { object-fit: cover; width: 100%;  height: 320px;}

    .home-testimonial { background: #E0DBD9; padding: 50px 0px;}
    .testi-image { padding-right: 12px !important;}
    .no-leftpadd { padding-left: 12px !important;}
    .testi-image img { border-radius: 10px 10px 40px 0px;}
    .testimain-box { border-radius: 0px 40px 10px 10px; padding: 22px;}

    .home-quote { padding: 140px 0px; background-position: center;}
    .home-quote label { margin: 20px 0px 22px;}
    .home-quote h4 { font-size: 26px; margin: 0px auto; width: 82%;}

    .home-process { padding: 50px 0px; margin-bottom: 0px;}
    .home-process .col-lg-12{ position: relative;}
    .vector-heading .heading { margin: 30px 0px 40px;}
    .process-row { flex-direction: column; width: 100%; align-items: end; padding-left: 52px;}
    .process-box { padding: 20px; width: 100%; margin: 15px 0px;}
    .process-top .process-box:before, 
    .process-bottom .process-box:before { bottom: auto; height: 1px; width: 36px; left: -36px; right: auto; top: 30px;}
    .process-top .process-box:after, 
    .process-bottom .process-box:after { bottom: auto; top: 22px; right: auto; left: -36px; border-width: 8px 0 8px 8px; border-color: transparent transparent transparent #B8ABA5;}
    .process-top{padding-top: 22px;}
    .process-bottom{padding-bottom: 22px;}
    .process-divider { position: absolute; margin: 0px; flex-direction: column; height: 100%; left: 15px; top: 0; bottom: 0;}
    .process-divider img { width: 26px; background: none;}
    .process-divider::before { height: 100%; width: 1px; left: 50%; top: 0; bottom: 0; transform: translateX(-50%); z-index: 0;}

    .portfolio-slidebox { padding: 0px 5px;}
    .portfo-arrows { margin-top: 30px;}

    .ceo-message { margin: 50px 0px;}
    .ceomessage-box { padding: 0px 50px;}
    .ceomessage-box h4 { font-size: 26px;}
    .message-shape-2 { right: -23px; width: 90px;}
    .message-shape-1 { left: -23px; width: 90px;}

    .home-story { padding: 50px 0px; background: #000000;}
    .homepage-testi .quality-row { border-radius: 0px; padding: 0px;}
    .storyarea-head .heading { font-size: 45px;}
    .storyarea-point { margin-top: 20px;}

    .story-slidebox {padding: 0px 5px;}
    .story-slidebox div {padding: 20px;}
    .story-slidebox div p { height: 160px;}

    .home-service { margin: 50px 0px;}
    .home-service .heading { margin-bottom: 40px;}
    .service-slider{ padding-bottom: 40px;}
    .service-slider .slick-slide{ padding: 0px 5px;}

    .home-media { margin: 50px 0px;}
    .homemedia-box { padding: 30px 50px 40px;}
    .media-shape-1 { left: -23px; width: 90px;}
    .media-shape-2 { right: -23px; width: 90px;}
    .homemedia-box .shape-divider { margin: 20px 0px;}
    .medialogo-slider { margin: 0px;}
    .medialogo-slider .slick-slide div { padding: 0px 10px;}

    .get-touch { padding: 50px 0px;}
    .gettouch-text .heading { text-align: center; margin-bottom: 50px;}
    .gettouch-text p { display: none;}
    .gettouch-form { padding: 70px 40px 40px; margin-left: 0px;}

    .footer { padding: 30px 0px 12px; margin-bottom: 56px;}
    .footlink-1{ text-align: center; width: 100%;}
    .footlink-1 li { margin-right: 0px; margin-bottom: 3px;display: block;}
    .footlink-1 li a { letter-spacing: 1.5px;}
    .footlink-2{display: none;}
    .footer-logo { margin: 30px 0px 50px; flex-direction: column-reverse;}
    .subscribe-form { width: 340px;}
    .subscribe-form p { font-size: 17px; margin-bottom: 12px; text-align: center;}
    .footer-logo .img-responsive { width: 210px; margin-top: 40px;}
    .social-copywrite p { color: rgb(255 255 255 / 35%);}
    .social-copywrite { justify-content: center; flex-direction: column;}
    .desktop-footersocial{display: none;}
    .mobile-footersocial{ display: block;}
    .footer-social li { margin: 0px 13px;}
    .footer-social li a { font-size: 13px;}
    .footer-term{ display: block;}

    .page-filter {padding: 25px 0px;}
    .page-filter ul {display: none;}
    .filter-dropdown {display: block;}

    .faq-page { background: #fff; padding-bottom: 40px; margin-top: 10px;}
    .faq-accord .accordion-button { font-size: 15px;}
    .faq-leftside{ padding-right: 12px !important;}
    .faq-rightside{ padding-left: 12px !important;}
    .faq-more{ display: block;}

    .sub-header .img-responsive{ width: 100%; height: 300px; object-fit: cover;}
    .sub-header .about-banner-wrap, .sub-header .about-banner-wrap img{min-height: 300px;}
    .subheader-text { bottom: 40px;}

    .media-page { margin: 30px 0px 40px;}
    .mediapost-more { margin-top: 10px !important;}
    .medialist-box div span { min-height: auto;}

    .project-listing { margin-bottom: 40px;}
    .projectlist-box div { padding: 20px;}
    .projectlist-more{ display: block; margin-top: 30px !important;}
    .projectlist-box .img-responsive{ object-fit: cover; height: 380px;}
    .projectlist-box div span:before{display: none;}
    .projectlist-box div h4, .projectbig-box h4 { font-size: 40px;}
    .projectlist-curve, .projectbig-curve { width: 90px;}
    .projectbig-box{ padding: 20px 20px 58px;}
    .projectdetail-slider .slick-slide div .img-responsive{ object-fit: cover; height: 440px;}
    .projectdetail-slider .slick-dots { bottom: 25px;}
    .projectlist-box div span, .projectbig-box span { font-size: 12px;}
    .projectlist-box div span:after{ margin: 0; width: 50%; margin-left: 12px; flex: none;        margin-bottom: 4px;}
    .projectbig-box span:before{display: none;}
    .projectbig-box span:after { flex: none; margin: 0 auto; position: absolute; bottom: 0; width: 32%; left: 0; right: 0;}
    .projectbig-box span { padding-bottom: 8px; display: block;}
    .small-projectlist.projectlist-box div span{justify-content: space-between; flex-wrap: wrap;}
    .small-projectlist.projectlist-box div span small{width: 48%;margin-top: 3px;}
    .small-projectlist.projectlist-box div span small:nth-child(even){text-align: right;}
    .small-projectlist.projectlist-box div span small strong{ display: none;}
    .small-projectlist.projectlist-box div span:after{ display: none;}

    .contactpage-form {padding: 40px 0px 30px;}
    .contactpage-form::before { width: 46%;}
    .contact-map{display: none;}
    .contact-form {padding-left: 0px; text-align: center;}
    .contact-address { flex-direction: column; align-items: center; margin-top: 30px;}
    .contact-address div { width: 50%; text-align: center;}
    .contactpage-career { margin-bottom: 50px;}
    .vendor-career { flex-direction: column;}
    .contact-career { padding: 60px 25px 25px; width: 100%;}
    .contact-career h4 { font-size: 26px;}
    .contactjob-list { align-items: start; padding: 18px; flex-direction: column;}
    .contactjob-list span {font-size: 18px; margin-bottom: 8px; width: 100%;}

    .career-page { margin: 50px 0px;}
    .careerpage-head {padding-right: 0px; text-align: center; margin-bottom: 40px;}
    .careerjob-list span { font-size: 18px; margin-bottom: 8px; width: 100%;}
    .careerjob-list { align-items: start; flex-direction: column;}
    .career-listing p {font-size: 20px;}

    .career-popup .modal-body { padding: 20px;}
    .career-popup .modal-dialog { width: auto;}
    .careerpop-head { font-size: 18px;}
    .job-detail div:nth-child(2){ margin: 0px 10px;}
    .pop-close { width: 25px; height: 25px; right: 15px; top: 15px; font-size: 11px;}
    .pop-close img { width: 8px;}
    .careerpop-content form textarea { height: 70px;}


    .servicepage-detail { padding: 50px 0px; background: linear-gradient(to top, #F3F1F0, rgb(255 255 255 / 0%) 60%);}
    .servicepage-tag { margin-left: 0px; margin-bottom: 0px;}
    .servicepage-detail .build-slider { margin: 15px 0px 0px;}
    .servicepage-detail .buildslide-box div { margin: 20px 0px 0px 0px;}
    .servicepage-detail .homebuild-image { margin-bottom: 40px;}
    .servicepage-detail .build-arrows { margin-top: 20px;}
    .servicepage-detail .homebuild-shape, 
    .servicepage-detail .homebuild-shape-2 {filter: brightness(0) invert(1); }
    .serviceslide-image { margin-bottom: 50px;}
    .other-service { padding: 50px 0px; background: linear-gradient(to bottom, #F3F1F0, rgb(255 255 255 / 0%) 80%);}
    .other-service .service-box { margin-bottom: 20px;}
    /* .other-service .port-curve-1 { filter: brightness(0) invert(1);} */
    .servicedetail-curve { filter: brightness(0) invert(1);}
    .servicepage-head { padding: 30px 0px;}
    .servicepage-slider { margin: 0px;}
    .service-keypoint { margin: 50px 0px 35px;}
    .keypoint-box { margin-bottom: 15px; min-height: auto;}
    .other-service .portfolio-slider { padding-bottom: 25px;}
    .otherproj-arrows{ display: flex;}
    .service-process .quality-row { padding: 40px 5px 0px;}
    
    .bloglist-card-box{margin-top: 30px;}
    .bloglist-cards{margin-bottom: 50px;}
    .projectdetail-head { margin: 50px 0px;}
    .prodetail-image {padding-right: 0px; margin-bottom: 30px;}
    .prodetail-content span::after { width: 89%;}
    .prodetail-content .heading { margin-top: 20px; font-size: 42px;}
    .prodetail-content ul { margin: 20px 0px !important;}
    .prodetail-content ul li { font-size: 14px; margin: 6px 0px; width: 50%; vertical-align: middle;}
    .porfolio-view img { height: 350px !important;}
    .prodetail-other { margin: 50px 0px;}
    .project-otherslider { padding-bottom: 30px;}
    .project-otherslider .slick-slide > div { padding: 0px 5px;}

    .thankyou-text { height: auto;}
    .thankyou-text div { position: unset; transform: none;}
    .thankyou-text .heading { font-size: 60px; letter-spacing: 3px; margin-bottom: 20px;}

    .blogdetail-content{padding-bottom: 50px; padding-top: 50px;}
    .blogdetail-box{margin: 0;}
    .blogdetail-inner{padding: 70px 20px;}
    .blogdetail-inner h1{ font-size: 22px;}
    .bloginner-image{flex-direction: column;}
    .bloginner-image img{width: 100%; margin-bottom: 30px;}
    .bloginner-image img:last-child{margin-bottom: 0px;}
    .blogdetail-social{flex-direction: column;}
    .blogdetail-social span{margin-right: 0; margin-bottom: 20px; font-weight: 600;}
    .blogdetail-social ul li a{width: 30px; height: 30px;}
    .blogdetail-other{margin: 50px 0 60px;}
    .otherblog-arrows{display: flex; align-items: center; justify-content: center; margin-top: 50px;}
    .otherblog-arrows .default-arrowleft, 
    .otherblog-arrows .default-arrowright{position: static; margin: 0 8.5px; transform:rotate(45deg);}
    .otherblog-slider .slick-slide > div { padding: 0px 5px;}

    .errorpage-text div { bottom: auto; transform: translateY(-50%); top: 50%;}
    .errorpage-text .heading{font-size: 90px;}

    .aboutpage-head{margin: 50px 0px;}
    .abouthead-detail .heading { margin: 20px 0px 25px;}
    .abouthead-detail span::after{width: 89%;}
    .abouthead-image{display: none;}
    .aboutvideo-box .img-responsive{height: 380px; object-fit: cover;}
    .aboutvideo-box video { height: 380px;}
    .aboutvideo-box span{text-align: center;}
    .aboutvideo-box div{top: 60%; transform: translate(-50%, -60%);}
    .aboutvideo-box span br{display: block;}
    .vision-detail p{padding-left: 0; margin: 30px 0px 35px;}
    .aboutpage-vision{margin: 50px 0;}
    .vision-detail{padding-right: 0; margin-top: 20px;}
    .design-image .slick-slider { border-radius: 10px 10px 35px 35px;}
    .no-rightpadd{padding-right: 12px!important;}
    .aboutdesign-slider{border-radius: 35px 35px 10px 10px; padding: 30px;}
    .designslide-box .heading { width: auto;}
    .leader-area{padding: 50px 0px 25px;}
    .leadership-box{flex-direction: column; margin-bottom: 25px;}
    .leadership-box .img-responsive{width: 100%;border-radius: 10px 10px 35px 35px;}
    .leadership-box div{width: 100%;border-radius: 35px 35px 10px 10px;}
    .leadership-box div span { font-size: 26px;}
    .aboutquote-box{padding: 40px 30px;}
    .aboutquote-box p{width: 85%; font-size: 15px;}
    .aboutquote-box span{width: 240px; margin: 0 auto;}
    .aboutquote-box .heading{font-size: 30px;}
    .about-partner { margin-bottom: 25px;}
    .about-footprint { margin-top: 50px;}
    .fliplogo-card { height: 50px; width: 33%;}
    .about-service .home-service{margin: 50px 0px;}
    .home-service .default-btn{display: none;}
    .about-faq{padding: 50px 0;}
    .aboutpage-quality{ margin-bottom: 50px;}
    .about-team { margin-bottom: 35px;}
    .team-more { margin-top: 20px;}
    .team-box span { font-size: 17px;}
    .about-team .row .col-6:nth-child(odd){ padding-left: 7px;}
    .about-team .row .col-6:nth-child(even){ padding-right: 7px;}
    .teampopup-box { flex-direction: column;}
    .teampopup-box .img-responsive { width: 100%;}
    .teampopup-box div { width: 100%; padding: 22px; border-top: 1.5px solid #B7AAA4; border-left: 0px;}
    .teams-popup .modal-dialog { max-width: 300px; margin: var(--bs-modal-margin) auto;}
    .desktop-aboutmedia{display: none;}
    .mobile-aboutmedia{ display: block;}
    .about-media { background: #fff; padding: 30px 0px 50px;}
    .aboutmedia-box .mediabox-detail { padding: 30px;}
    .aboutmedia-box .mediabox-logo { padding: 30px;}
    .aboutmedia-box img { width: 130px;}
    .mobile-aboutmedia .slick-slider .slick-slide > div { padding: 0px 10px;}
    .mobile-aboutmedia .slick-slider { padding-bottom: 30px; margin-bottom: 20px;}
    .aboutmedia-filter { margin-bottom: 35px !important; display: flex; align-items: center; overflow-y: auto; margin-right: -70px !important; padding-right: 70px !important;}
    .aboutmedia-filter li a { width: max-content;}
    .aboutmedia-filter::-webkit-scrollbar{width: 0px;}
    .mobile-abouthistory{ display: block;}
    .desktop-abouthistory{ display: none;}
    .about-history .center-heading { margin-bottom: 40px;}
      
    .jobhead-image { top: 0px; padding-right: 0px; margin: 0px 0px 30px;}
    .career-detail { padding: 35px 0px 50px;}
    .job-typelocate { padding: 12px 0px; margin: 30px 0px 25px; justify-content: space-between;}
    .job-typelocate div { margin-right: 0px; width: 49%;}
    .job-discrip .default-btn{width: 100%;}

    .service-listpage { margin: 50px 0px 30px;}
    .servicelist-box .img-responsive { height: 380px;}
    .servicelist-box div { padding: 0px 20px 25px;}
    .servicelist-box div h4 { font-size: 40px;}

    .service-namefixed { padding: 14px 12px; top: 0;}
    .service-namefixed .heading { font-size: 30px;}

    .term-policy { padding: 35px 0px 50px;}
    .termpolicy-text {padding-right: 0px; margin-bottom: 40px;}
    .termpolicy-text .heading { margin-bottom: 15px;}
    .policyaccord-head { font-size: 16px;}

    .buildslide-image{min-height: 260px;}

    .sub-header.project-sub{min-height: 300px;}
    .projectdetail-big{min-height: 440px;}
    .small-projectlist.projectlist-box{min-height: 380px;}

    .medialist-box small{min-height:236px;}

  } 


  @media (min-width: 320px) and (max-width: 355px) {
    .subscribe-form { width: 310px;}
    .footer-social li { margin: 0px 11px;}
    .service-namefixed .heading { font-size: 27px;}
    .story-slidebox div p { min-height: 117px;}
  }

  @media (min-width: 576px) and (max-width: 599px) {
  .manu-imageslider { border-radius: 10px; margin: 50px 0px !important;}
  }

  @media (min-width: 800px) and (max-width: 800px) {
    .quality-box {  min-height: 333px;}
  }


  